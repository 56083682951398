module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Breaker Toolbox","short_name":"Breaker Toolbox","start_url":".","display":"minimal-ui","icon":"src/images/djawn-logo-96x96.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"23ab60039ad07320871b1fcc4574d31e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2MJDXL9MSL"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0,"send_page_view":true}},
    },{
      plugin: require('../plugins/fb-pixel/gatsby-browser.js'),
      options: {"plugins":[],"enable":true,"pixelId":"512236487520107"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
