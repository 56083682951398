export const SHIPPING_FEE_PRODUCT_ID = 'DJAWN-SHIPPING';
export const EVENT_STOPPED_STATUS = 60;
export const EVENT_PLANNED_STATUS = 68;
export const EVENT_PAUSED_STATUS = 61;
export const EVENT_LIVE_STATUS = 59;
export const EVENT_CANCELLED_STATUS = 103;
export const EVENT_PREVIEW_STATUS = 471;
export const RANDOMIZED = 9;
export const PICK_YOUR_SLOTS = 10;
export const RIP_N_SHIP = 11;
export const COPY_EXISTING_EVENT = -1;
export const AUCTION = 12;
export const REFUND_REASON_REFERENCE_TYPE_ID = 105;
export const REFUND_DECLINE_REASON_REFERENCE_TYPE_ID = 107;
export const SLOT_STATUS_SOLD = 49;
export const DJAWN_USER_ID = 199;
export const ORDER_DETAIL_STATUS_COMPLETED = 286;
export const FREE_SHIPPING_REFERENCE_DATA = 138;
export const SHIPPING_TYPES_REFERENCE_TYPE = 39;
export const SHIPPING_LABEL_STATUSES_REFERENCE_TYPE = 210;
export const SHIPPING_CARDS_REFERENCE_TYPE = 38;
export const PRODUCT_SELECTION_REFERENCE_TYPE_ID = 145;
export const ALL_PRODUCTS_PRODUCT_SELECTION_OPTION = 335;
export const SELECT_PRODUCTS_PRODUCT_SELECTION_OPTION = 336;
export const MAXIMUM_EVENT_COPIES_ALLOWED_REFERENCE_DATA_ID = 451;
export const DEFAULT_COPY_NUMBER_REFERENCE_DATA_ID = 452;

export const ACTIVE_PRODUCT_STATUS_ID = 118;

export const CURRENT_CHAT_ID_FOR_NON_LIVE_EVENT = 'current-chat-id-for-non-live-event';
export const WEBSOCKET_LIVE_EVENT_CHANNEL_GROUP_NAME = 'Web-Socket-Channel-Group';
export const CHECKOUT_SESSION_ID = 'Checkout-Session-Id';

export const DEFAULT_RIPNSHIP_PRODUCTS_PER_PAGE = 15;

export const SHOPPING_CART_CART_MODIFIED_TOAST_MESSAGE = 'Your shopping cart has been modified.  Please click checkout again to get an updated payment total.';
export const EVENT_EXPIRATION_IN_SECONDS_REFERENCE_TYPE_NAME = 'EventExpirationAfterSellerIsDisconnectedInSeconds';

export const VIEWS_DESKTOP_MIN_WIDTH = 612;
export const VIEWS_DESKTOP_MAX_WIDTH = 1100;

export const MAXIMUM_NICKNAME_CHARACTER_MESSAGE_BEFORE_TRUNCATE = 35;

export const DEFAULT_AUTH_ERROR_MESSAGE = '⚠️ Authentication Failed! Please try again.';
