import axios from 'axios';
import env from '../../environment.config';
import { getAccessToken } from '../utils/auth';
import { CHECKOUT_SESSION_ID, WEBSOCKET_LIVE_EVENT_CHANNEL_GROUP_NAME } from '../utils/constants';

const axiosConfig = axios.create({
	baseURL: `${env.api_url}api/`
});

axiosConfig.interceptors.request.use((config) => {
	config.headers = { Authorization: `Bearer ${getAccessToken()}` };
	return config;
});

export const getConfigHeadersWithWebSocketNameHeaders = (websocketGroupName) => {
	const config = { ...axiosConfig.defaults };
	const token = getAccessToken();
	config.headers.common[WEBSOCKET_LIVE_EVENT_CHANNEL_GROUP_NAME] = websocketGroupName;
	config.headers.common.Authorization = `Bearer ${token}`;
	return config;
};

export const getConfigHeadersWithCheckoutSessionIdHeaders = (checkoutSessionId) => {
	const config = { ...axiosConfig.defaults };
	const token = getAccessToken();
	config.headers.common[CHECKOUT_SESSION_ID] = checkoutSessionId;
	config.headers.common.Authorization = `Bearer ${token}`;
	return config;
};

export const getConfigDefaultHeadersWithAdditionalHeaders = (headers) => {
	const config = { ...axiosConfig.defaults };
	const token = getAccessToken();
	config.headers.common.Authorization = `Bearer ${token}`;
	if (headers && headers.length) {
		headers.forEach((header) => {
			config.headers.common[header.key] = header.value;
		});
	}
	return config;
};

export const getConfig = () => {
	const config = { ...axiosConfig.defaults };
	const token = getAccessToken();
	config.headers.common.Authorization = `Bearer ${token}`;
	return config;
};

export default axiosConfig;
