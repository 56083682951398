/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

export const onRouteUpdate = ({ location }, pluginOptions) => {
	const { enable } = pluginOptions || {};
	// Don't track while developing.
	if (enable && typeof fbq === 'function') {
		fbq('track', 'ViewContent');
	}
};
