import { SET_SAVED_PRODUCT_NOTIFICATION, RESET_SAVED_PRODUCT_NOTIFICATION } from '../types';

const initialState = {
	savedProductNotificationMessage: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_SAVED_PRODUCT_NOTIFICATION:
			return { ...state, savedProductNotificationMessage: action.message };
		case RESET_SAVED_PRODUCT_NOTIFICATION:
			return { ...state, savedProductNotificationMessage: action.message };
		default:
			return state;
	}
};
