import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

/* eslint-disable @typescript-eslint/no-unused-vars */
const createNoopStorage = () => ({
	getItem(_key: string) {
		return Promise.resolve(null);
	},
	setItem(_key: string, value: string) {
		return Promise.resolve(value);
	},
	removeItem(_key: string) {
		return Promise.resolve();
	},
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

export default storage;
