import { SET_DARK_MODE, SET_LIGHT_MODE } from '../types';

const initialState = {
	isDarkMode: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_DARK_MODE:
			document.documentElement.classList.add('dark');
			return { ...state, isDarkMode: true };
		case SET_LIGHT_MODE:
			document.documentElement.classList.remove('dark');
			return { ...state, isDarkMode: false };
		default:
			return state;
	}
};
