import {
	CHAT_SCROLLING_STARTED, CHAT_SCROLLING_DONE, SHOW_MOBILE_SEARCH, UPDATE_MESSAGES_COUNT, SET_USER_ID
} from '../types';

const initialState = {
	showMobileSearch: false,
	chatScrolling: false,
	unreadMessages: 0,
	openedWindow: null,
	userId: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_MOBILE_SEARCH:
			const currentShowMobileSearch = { ...state }.showMobileSearch;
			return { ...state, showMobileSearch: !currentShowMobileSearch };
		case CHAT_SCROLLING_STARTED:
			return { ...state, chatScrolling: true };
		case CHAT_SCROLLING_DONE:
			return { ...state, chatScrolling: false };
		case UPDATE_MESSAGES_COUNT:
			return { ...state, unreadMessages: action.unreadMessages };
		case SET_USER_ID:
			return { ...state, userId: action.userId };
		default:
			return state;
	}
};
