import {
	SET_IS_SELLER
} from '../types';

const initialState = {
	user: null,
	isSeller: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_IS_SELLER:
			return { ...state, isSeller: action.isSeller };
		default:
			return state;
	}
};
