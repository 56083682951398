import {
	SET_BUY_NOW_DATA
} from '../types';

const initialState = {
	buyNowData: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_BUY_NOW_DATA:
			return { ...state, buyNowData: action.buyNowData };
		default:
			return state;
	}
};
