export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';
export const UPDATE_ITEM_TIMER = 'UPDATE_ITEM_TIMER';
export const SHOW_MOBILE_SEARCH = 'SHOW_MOBILE_SEARCH';
export const CHAT_SCROLLING_STARTED = 'CHAT_SCROLLING_STARTED';
export const CHAT_SCROLLING_DONE = 'CHAT_SCROLLING_DONE';
export const UPDATE_MESSAGES_COUNT = 'UPDATE_MESSAGES_COUNT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_LIGHT_MODE = 'SET_LIGHT_MODE';
export const SET_SAVED_PRODUCT_NOTIFICATION = 'SET_SAVED_PRODUCT_NOTIFICATION';
export const RESET_SAVED_PRODUCT_NOTIFICATION = 'RESET_SAVED_PRODUCT_NOTIFICATION';
export const SET_CURRENT_CHECKOUT_SESSION_ID = 'SET_CURRENT_CHECKOUT_SESSION_ID';
export const SET_BUY_NOW_DATA = 'SET_BUY_NOW_DATA';
export const SET_IS_SELLER = 'SET_IS_SELLER';
export const UPDATE_SHIP_ONLY_HITS = 'UPDATE_SHIP_ONLY_HITS';
