import axios from 'axios';
import axiosConfig, { getConfig } from './config';
import { getCurrentUser, getAccessToken } from '../utils/auth';
import env from '../../environment.config';

export const authHeaders = () => ({ headers: { Authorization: `Bearer ${getAccessToken()}` } });

export const checkAuthorization = async () => await axiosConfig.post('account/check-authorization', null);

export const refreshToken = async () => {
	const user = getCurrentUser();
	const req = { token: user.token, refreshToken: user.refreshToken };
	const config = { headers: { Authorization: `Bearer ${getAccessToken()}` } };
	const res = await axiosConfig.post(
		'account/refreshtoken',
		req,
		config
	);
	window.localStorage.gatsbyUser = JSON.stringify({
		name: user.name,
		firstName: user.firstName,
		lastName: user.firstName,
		pic: user.pic,
		id: user.id,
		email: user.email,
		token: res.data.token,
		refreshToken: res.data.refreshToken
	});
	return res;
};

export const facebookAuthentication = async (req) => await axiosConfig.post('account/authenticate/facebook', req);
export const appleAuthentication = async (req) => await axiosConfig.post('account/authenticate/apple', req);
export const getFacebookUserDataAccessTokenFromCode = async (code, redirectUrl) => await axiosConfig.post(`account/facebook/user_data/${code}/${encodeURIComponent(redirectUrl)}`);
export const googleAuthentication = async (req) => await axiosConfig.post('account/authenticate/google', req);
export const customAuthentication = async (req) => await axiosConfig.post('account/authenticate/custom', req);
export const getProfile = async (fetchSellerProfile = false, fetchSellerLogo = false) => {
	const config = getConfig();
	config.params = { fetchSellerProfile, fetchSellerLogo };
	return await axiosConfig.get('account/profile', config);
};
export const updateProfileData = async (profile) => await axiosConfig.put('account/profile', profile);
export const getImageByNickname = async (nickname) => await axiosConfig.get(`account/profile/image?nickname=${nickname}`);
export const uploadProfileImage = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'account/profile/image',
		formData,
		headers
	);
};
export const getUserRoles = async () => await axiosConfig.get('account/roles');
export const loadProfileByUserId = async (userId) => await axiosConfig.get(`account/profile/${userId}`);
export const getAuthenticatedUserId = async () => await axiosConfig.get('account/user/id');
export const searchUsers = async (searchText) => await axiosConfig.get(`account/users/search/${searchText}`);
export const getUserById = async (userId) => await axiosConfig.get(`account/users/${userId}`);
export const getUsersByIds = async (userIds) => {
	if (!userIds || !userIds.length) return null;
	return await axiosConfig.post('account/users/list', { userIds });
};

// returns the filename
export const uploadMessageAttachment = async (formData, token) => {
	let headers = authHeaders();
	if (token) {
		headers = { headers: { Authorization: `Bearer ${token}` } };
	}

	headers['Content-Type'] = 'multipart/form-data';
	headers.redirect = 'follow';
	headers.mode = 'no-cors';
	return await axiosConfig.post(
		'file/upload',
		formData,
		headers
	);
};

export const getMessageAttachmentFolder = async () => await axiosConfig.get('file/folder/message');
export const getFileBytes = async (fileName) => await axiosConfig.get(`file/attachment/${fileName}`);
export const getRefundFileBytes = async (fileName) => await axiosConfig.get(`file/refunds/${fileName}`);
export const sendVerificationCode = async (phoneNumber, phoneNumberToken = null) => await axiosConfig.post('account/phone/verify/code/send', { phoneNumber, phoneNumberToken });
export const verifyPhoneNumberCode = async (data, twoFactorToken) => {
	const config = { headers: {} };
	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	if (twoFactorToken) {
		config.headers['Two-Factor-Token'] = twoFactorToken;
	}

	return await axios.post(`${env.api_url}api/account/phone/verify`, data, config);
};

export const findAccount = async (data) => await axiosConfig.post('account/find', data);
export const sendResetCode = async (data) => await axiosConfig.post('account/reset/code/send', data);
export const verifyResetCode = async (data, phoneNumberCodeToken) => {
	const config = { headers: {} };
	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	if (phoneNumberCodeToken) {
		config.headers['Phone-Number-Code-Token'] = phoneNumberCodeToken;
	}

	return await axios.post(`${env.api_url}api/account/reset/code/verify`, data, config);
};
export const resetPassword = async (data, resetPasswordToken) => {
	const config = { headers: {} };
	const accessToken = getAccessToken();
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	if (resetPasswordToken) {
		config.headers['Reset-Password-Token'] = resetPasswordToken;
	}

	return await axios.post(`${env.api_url}api/account/reset/password`, data, config);
};
export const phoneNumberIsAvailable = async (phoneNumber, excludedUserId) => {
	const config = getConfig();
	config.params = { phoneNumber, excludedUserId };
	return await axiosConfig.get('account/phone/available', config);
};
export const userHasPendingEmailConfirmation = async (email, token) => {
	const config = getConfig();
	config.params = { email, emailConfirmationToken: token };
	return await axiosConfig.get('account/pending/email-confirmation', config);
};
export const expireVerificationCode = async (verificationId, userId = null) => await axiosConfig.post('account/code/expire', { verificationId, userId });
export const sendEmailVerificationCode = async (email) => await axiosConfig.post('account/email/verification/code/send', { email });
export const verifyEmailVerificationCode = async (email, code) => await axiosConfig.post('account/email/verification/code/verify', { email, code });
export const getUsers = async () => await axiosConfig.get('account/users/list');
export const updateUserStatus = async (userId, isActive) => await axiosConfig.post('account/user/update-status', { userId, isActive });
export const unsubscribeFromNotifications = async (data) => await axiosConfig.put('account/notifications/unsubscribe', data);
export const getCachedUser = async () => await axiosConfig.get('account/user/cached');
export const getAccount = async (slug) => await axiosConfig.get('account', { params: { slug } });
export const getAccountStreams = async (sellerId, pastStreams, page = null, perPage = null, searchValue = '') => await axiosConfig.get('account/streams', {
	params: {
		sellerId, pastStreams, page, per_page: perPage, searchValue
	}
});
export const expireFileExports = async () => await axiosConfig.post('account/file-exports/expire');
